import { Box, Button, Heading, Img, Text, useStyleConfig, useBreakpointValue, Show } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { AppPropsWithLayout, NextPageWithLayout } from '~pages/_app'
import useTranslation from 'next-translate/useTranslation'
import { routes } from '~components/sielbleu/utils/routes'
import { useRouter } from 'next/router'
import { FPI } from '~components/sielbleu/utils/const'
import ScheduleIcon from '~ui/icons/ScheduleIcon'
import WorkoutIcon from '~ui/icons/WorkoutIcon'
import CallCoachIcon from '~ui/icons/CallCoachIcon'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { GetServerSidePropsContext } from 'next'
import { countries, CountryInterface } from '~components/sielbleu/register/utils/countries'
import { clientGeo } from '~components/sielbleu/utils/clientGeo'
import TransText from 'next-translate/TransText'
import Head from 'next/head'
import HeadLinks from '~components/sielbleu/head/links'
import Layout from '~components/sielbleu/layout'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { req } = context
    const geo = clientGeo(req)

    return {
        props: { countryCode: geo?.country || null },
    }
}

const Page: NextPageWithLayout = ({ countryCode }: AppPropsWithLayout['pageProps']) => {
    const { t, lang } = useTranslation('sielbleu')
    const router = useRouter()
    const { query } = router
    const imgPath = '/images/sielbleu/'
    const devicesImage = useBreakpointValue({
        base: imgPath + 'landing/devices_webmobile.png',
        md: imgPath + 'landing/devices_web.png',
    })
    const visitorCountry = countries.find((country: CountryInterface) => {
        return country.alpha2 === countryCode
    })
    const pageStyles = useStyleConfig('Landing', { variant: FPI })

    const handleRegisterRedirection = () => {
        window.location = ('//' +
            process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN +
            '/' +
            lang +
            routes.public.register +
            '?' +
            new URLSearchParams({
                ...query,
                type: FPI,
                country: visitorCountry?.alpha2 || '',
            }).toString()) as any
    }

    const partners = [
        {
            link: 'https://www.eu-ipff.org/',
            name: 'euipff',
            path: 'pfandme/logo_euipff.png',
        },
        {
            link: 'https://www.asso-fpf.com/',
            name: 'afpf',
            path: 'partners/logo_afpf.png',
        },
        {
            link: 'https://pulmonaryfibrosistrust.org/',
            name: 'pulmonary fibrosis trust',
            path: 'pfandme/logo_pulmonary_fibrosis_trust.png',
        },
        {
            link: 'https://oltrelamalattia.it/',
            name: 'padova',
            path: 'pfandme/logo_padova.png',
        },
        {
            link: 'http://fibrosispulmonar.es/',
            name: 'afefpi',
            path: 'pfandme/logo_afefpi.png',
        },
    ]

    return (
        <>
            <HeadLinks />
            <Head>
                <title>
                    Siel Bleu {t('sielbleu.landing.fpi.content')} - {t('sielbleu.landing.adapted-physical-activity')}
                </title>
                <meta name="description" content={t('sielbleu.landing.fpi.description.text')} />
                <meta property="og:title" content={t('sielbleu.landing.fpi.title')} />
                <meta property="og:site_name" content="Siel Bleu FPI" />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_LANDING_FPI_URL} />
                <meta property="og:description" content={t('sielbleu.landing.fpi.description.text')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={process.env.NEXT_PUBLIC_LANDING_FPI_URL + '/images/sielbleu/pfandme/header_image.jpg'}
                />
            </Head>
            <Box __css={pageStyles}>
                <Box className="header-banner">
                    <Heading className="main-title">{t('sielbleu.landing.fpi.title')}</Heading>
                    <Heading className="motivation-title">{t('sielbleu.landing.fpi.motivation')}</Heading>
                    <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                </Box>
                <Box className="container">
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.landing.fpi.description.title')}</Heading>
                            <Text className="section">{t('sielbleu.landing.fpi.description.text')}</Text>
                        </Box>
                        <Img src={devicesImage} alt="devices" />
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.fpi.details.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-blue">
                                <ScheduleIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.fpi.details.card-1.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.fpi.details.card-1.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <WorkoutIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.fpi.details.card-2.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.fpi.details.card-2.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <CallCoachIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.fpi.details.card-3.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.fpi.details.card-3.text')}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.fpi.pro.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-white">
                                <Box className="card-white-title one">
                                    <Heading>{t('sielbleu.landing.fpi.pro.card-1.title')}</Heading>
                                </Box>
                                <TransText
                                    text={t('sielbleu.landing.fpi.pro.card-1.text')}
                                    components={{
                                        link: (
                                            <a
                                                href="https://www.youtube.com/watch?v=GUHODxmymKk"
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                        text: <Text className="section"></Text>,
                                    }}
                                />
                            </Box>
                            <Box className="card-white">
                                <Box className="card-white-title two">
                                    <Heading>{t('sielbleu.landing.fpi.pro.card-2.title')}</Heading>
                                </Box>
                                <TransText
                                    text={t('sielbleu.landing.fpi.pro.card-2.text')}
                                    components={{
                                        link: (
                                            <a
                                                href="https://erj.ersjournals.com/content/52/suppl_62/PA4786"
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        ),
                                        text: <Text className="section"></Text>,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.fpi.partners')}</Heading>
                        <Show below="md">
                            <Carousel infiniteLoop={true} showThumbs={false} showStatus={false} showIndicators={false}>
                                {partners.map((l) => {
                                    return (
                                        <a href={l.link} key={l.name} target="_blank" rel="noreferrer">
                                            <Img src={imgPath + l.path} alt={l.name} />
                                        </a>
                                    )
                                })}
                            </Carousel>
                        </Show>
                        <Show above="md">
                            <Box display="flex">
                                {partners.map((l) => {
                                    return (
                                        <a href={l.link} key={l.name} target="_blank" rel="noreferrer">
                                            <Img src={imgPath + l.path} alt={l.name} />
                                        </a>
                                    )
                                })}
                            </Box>
                        </Show>
                    </Box>
                    <Box className="funded">
                        <Box>
                            <Text>{t('sielbleu.landing.fpi.funded')}</Text>
                        </Box>
                        <Box>
                            <a href="https://www.boehringer-ingelheim.com/" target="_blank" rel="noreferrer">
                                <Img src={imgPath + 'pfandme/logo_boehringer_ingelheim.png'} alt="" maxWidth="300px" />
                            </a>
                        </Box>
                    </Box>
                    <Box className="card-full-blue">
                        <Heading>{t('sielbleu.landing.fpi.customized')}</Heading>
                        <Text>{t('sielbleu.landing.fpi.text')}</Text>
                        <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const PageLanding = Page

PageLanding.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLanding
